import { MenuWidget } from '@qilab/widgets/models'
import QilabLogo from "./../../../assets/logo.svg";
import ResourceGuruLogo from "./../../../assets/resource-guru.jpg";
import ClickUpLogo from "./../../../assets/click-up.png";
import GitlabLogo from "./../../../assets/gitlab.svg";
import LokiLogo from "../../../assets/loki.png";
import GrafanaLogo from "../../../assets/grafana.png";
import KumaLogo from "../../../assets/kuma.svg";
import StoriesOnBoardLoago from "../../../assets/storiesonboard.png";
import CourseraLogo from "../../../assets/coursera.png";


export const SidebarItems: MenuWidget[] = [
  {
    id: '1',
    title: 'Dashboard',
    icon: QilabLogo,
    path: 'https://dashboard.qilab.it/'
  },
  {
    id: '2',
    title: 'Qonsuntivo',
    icon: QilabLogo,
    path: 'https://qonsuntivo.qilab.it'
  },
  {
    id: '2',
    title: 'Design System',
    icon: QilabLogo,
    path: 'https://webcomponents.qilab.it'
  },
  {
    id: '3',
    title: 'Gitlab',
    icon: GitlabLogo,
    path: 'https://gitlab.qilab.it/'
  },
  {
    id: '4',
    title: 'Resource Guru',
    icon: ResourceGuruLogo,
    path: 'https://app.resourceguruapp.com/hi/qilab/schedule'
  },
  {
    id: '5',
    title: 'ClickUp',
    icon: ClickUpLogo,
    path: 'https://app.clickup.com/4513767/home'
  },
  {
    id: '7',
    title: 'Application Logs',
    icon: LokiLogo,
    path: 'https://grafana.qilab.it/d/f4fedd62-fcd2-433c-9ff9-8664fb72abdb/logs'
  },
  {
    id: '8',
    title: 'Application Monitoring',
    icon: KumaLogo,
    path: 'https://grafana.qilab.it/d/ee2fu1lxo6io0e/applications'
  },
  {
    id: '9',
    title: 'Infrastructure Monitoring',
    icon: GrafanaLogo,
    path: 'https://grafana.qilab.it/d/8695c15c-9c4f-4453-b5de-eb17094b87be/infra'
  },
  {
    id: '10',
    title: 'StoriesOnBoard',
    icon: StoriesOnBoardLoago,
    path: 'https://app.storiesonboard.com/login '
  },
  {
    id: '11',
    title: 'Coursera',
    icon: CourseraLogo,
    path: 'https://www.coursera.org/programs/all/qi-lab-srl-rhlrm?currentTab=HOME'
  },
];
